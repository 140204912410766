/*CORE*/
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.menu-right {
  padding: 10px;
  float: right;
  color: white;
  margin: 10px -40px 0 0;
  font-size: 20px;
  cursor: pointer;
}

.menu-drawer .ant-drawer-header {
  height: 51px;
  background-color: #c23e37;
  border-radius: 0;
}

.menu-drawer .ant-drawer-title {
  color: #ffffff;
}

.menu-drawer .ant-menu-item-selected {
  color: #000000;
}

.menu-drawer .ant-menu .ant-menu-item-selected {
  background-color: #e4e3e5;
  color: #c23e37;
  font-weight: bold;
}

.menu-drawer .ant-menu-item:hover {
  color: #c23e37;
}

.menu-drawer .ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  border-right-color: #c23e37;
}

.menu-left {
  padding: 10px;
  float: left;
  color: white;
  margin: 10px 20px 0 -40px;
  font-size: 20px;
  cursor: pointer;
}

.messagesError {
  margin-top: 10px;
}

.messagesError div {
  background-color: #ef9a9a;
  color: #ffffff;
  padding-right: 40px;
}

.messagesError ul {
  list-style: none;
}

.messagesSuccess {
  margin-top: 10px;
}

.messagesSuccess div {
  background-color: #4caf50;
  color: #ffffff;
  padding-right: 40px;
}

.messagesSuccess ul {
  list-style: none;
}

.App {
  text-align: center;
}

.spin {
  text-align: center;
}

.team_dropdown {
  float: right;
  margin: 0 20px 0 0;
  font-size: 11pt;
}

.headerContents {
  margin-top: -7px;
}

.user-menu {
  float: right;
  margin: 10px 20px 0 0;
  padding: 10px;
  cursor: pointer;
}

.node_red_button {
  float: right;
  margin: 0 25px 0 0;
  cursor: pointer;
  font-size: 11pt;
}

.node_red_button_icon {
  cursor: pointer;
  font-size: 15pt;
}

.menu-drawer .ant-menu .ant-menu-item-selected {
  background-color: #ffffff;
  color: inherit;
  font-weight: normal;
}

.menu-drawer .ant-menu .ant-menu-item-selected::after {
  border-right-color: #ffffff;
}

.menu-drawer .ant-menu .ant-menu-item-selected {
  border-right: 0.5px solid rgb(228, 228, 228);
}

.menu-drawer .ant-menu .ant-menu-item-selected::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  border-right: 3px solid #1890ff;
  -webkit-transform: scaleY(0.0001);
  transform: scaleY(0.0001);
  opacity: 0;
}

/* Charts */
.chart-col {
  display: inline-block;
  position: relative;
  margin-right: 20px;
}

.chart-header {
  color: #d32f2f;
  text-align: center;
  background-color: #cfcfcf;
  padding: 5px;
}

#date_range p {
  font-size: 15pt;
}
/* --- */

/*THIS IS FOR THE ACCOUNTS PAGE*/
.account_form {
  font-weight: 400;
  font-size: 11pt;
  text-indent: 10px;
  color: darkred;
}
.account_data {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0;
  font-weight: 100;
  color: black;
}

.account_data a {
  font-weight: 400;
  cursor: pointer;
  padding: 10px;
}

.account_data a:hover {
  text-decoration: none;
}

.account_form h2 {
  margin-left: -20px;
}

.contact {
  text-align: center;
}

.ant-form-item {
  margin-bottom: 10px;
}

.ant-form-item-control {
  line-height: 30px;
}

/*List View*/
.row-icon-hide {
  display: none;
}

/* .row-icon-show {
  display: block;
} */

.ant-table-row .row-icon-show {
  display: inline;
}

.ant-table-row:hover .row-icon-hide {
  display: inline;
}

.row-warning {
  background-color: #fff3e0;
}

.row-error {
  background-color: #ffcdd2;
}

/* Horizontal Scroll For Tier Structures Steps */
.scroll-wrapper {
  display: flex;
  overflow-x: auto;
}

.scroll-wrapper .ant-steps-item {
  flex: 0 0 auto;
}

/* Ant Design Table */
.ant-table {
  border: 1px solid #cfcfcf13;
}

.ant-table-expand-icon-th,
.ant-table-row-expand-icon-cell {
  width: 1px;
  min-width: 1px;
}

.grecaptcha-badge {
  visibility: hidden;
}

/* Dropzone */

.dropzone {
  border: 2px dashed #eeeeee;
  background-color: #fafafa;
  border-radius: 5px;
  cursor: pointer;
  padding: 35px 20px 10px 20px;
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  outline: none;
  min-height: 100px;
}

.home-chart-bar {
  cursor: pointer;
}

.copy-key {
  display: none;
}

.ant-table-row:hover .copy-key {
  display: inline;
}
